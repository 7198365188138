import {
  swalErrorMessage,
  swalSuccessMessage,
} from "../../../../../Constant/swalMessage";
import {
  attachInboundNumberService,
  getCallSettingService,
  getCallerIdOtpService,
  getGatewayAssignedNumberService,
  resendCallerIdOtpService,
  saveByocSettingService,
  saveCallSettingService,
  verifyCallerIdOtpService,
} from "../../../services/callSetting.services";

import { callSettingType } from "./callSetting.type";
export const getCallSettingAction = (id) => async (dispatch) => {
  let res = await getCallSettingService(id);
  if (res) {
    await dispatch({
      type: callSettingType.GET_CALL_SETTING_DATA,
      payload: res?.data,
    });
  }
};

export const saveCallSettingsAction = (obj) => async () => {
  let res = await saveCallSettingService(obj);
  if (res?.status === 200) {
    swalSuccessMessage(res?.msg);
    return res?.status;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getCallerIdOtpAction = (obj) => async (dispatch) => {
  let res = await getCallerIdOtpService(obj);
  if (res?.status === 200) {
    swalSuccessMessage(res?.msg);
    return res?.status;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const resendCallerIdOtpAction = (obj) => async (dispatch) => {
  let res = await resendCallerIdOtpService(obj);
  if (res?.status === 200) {
    swalSuccessMessage(res?.msg);
    return res?.status;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const verifyCallerIdOtpAction = (obj) => async (dispatch) => {
  let res = await verifyCallerIdOtpService(obj);
  if (res?.status === 200) {
    swalSuccessMessage(res?.msg);
    return res?.status;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const clearCallSettingReducerAction = () => async (dispatch) => {
  await dispatch({
    type: callSettingType.CLEAR_CALL_SETTING,
    payload: {},
  });
};

export const saveByocSettingsAction = (obj) => async () => {
  let res = await saveByocSettingService(obj);
  if (res?.status === 200) {
    swalSuccessMessage(res?.msg);
    return res?.status;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getGatewayAssignedNumberAction = (gateway) => async () => {
  let res = await getGatewayAssignedNumberService(gateway);
  if (res) {
    return res;
  }
};

export const attachInboundNumberAction = (obj, gateway) => async () => {
  let res = await attachInboundNumberService(obj, gateway);
  if (res?.status) {
    swalSuccessMessage(res?.status);
  }
};
